import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { sendAsync } from 'utils/api';

const VALIDATE_URL = process.env.REACT_APP_REGULERINGSPLANFORSLAG_VALIDATE_URL;

function SubmitValidation({ username, onReset, uploadFiles, onResponse, onResponseReset }) {
    const apiLoading = useSelector(state => state.api.loading);

    async function validate() {
        if (!canValidate()) {
            return;
        }

        onResponseReset();

        const formData = new FormData();

        uploadFiles.forEach(uploadFile => formData.append(uploadFile.name, uploadFile.file));

        const response = await sendAsync(VALIDATE_URL, formData, username);

        if (response) {
            onResponse(response);
        }

        onReset();
    }

    function canValidate() {
        return username.trim() !== '' && uploadFiles.length > 0;
    }

    return (
        <Fragment>

            <Button onClick={validate} disabled={!canValidate() || apiLoading}>Validér</Button>
            {
                apiLoading ?
                    <Spinner animation="border" /> :
                    ''
            }


        </Fragment>
    );
}

export default SubmitValidation;